import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Helmet } from "react-helmet";
import Intro from '../components/Intro/Intro'

class Index extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Vikash Chander</title>
        </Helmet>
        <SEO title="vikash chander"/>
         <Intro/>
      </Layout>
    </React.Fragment>
    )
  }
}

export default Index;
