import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Name.style.css";
import FirebaseButton from "./Button.component";

function Name() {
  return (
    <React.Fragment>
      <code className='text-secondary big-text box-underline'>
        <span>root</span>@Vikash_Chander:$
        <span className='blinkText '>_Foxfy</span>
      </code>
      <FirebaseButton/>
    </React.Fragment>
  );
}

export default Name;
