import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Buttons() {
  return (
    <React.Fragment>
      <div className='d-flex flex-row flex-start'>
        <span className='badge badge-pill badge-primary m-1'>learner</span>
        <span className='badge badge-pill badge-success m-1'>programmer</span>
        <span className='badge badge-pill badge-info m-1 '>developer</span>
      </div>
    </React.Fragment>
  );
}