import React from "react"
import "./intro.css"
import "bootstrap/dist/css/bootstrap.min.css";

import Name from "../Name/Name.component"

class Intro extends React.Component {
  render() {
    return (
      <React.Fragment>
          <Name />
          <div className="text-capitalize mt-2">
            <p>
              Hi, I am <span className="underline-link">Vikash Chander </span>,
              A web developer Living in Delhi, India . Have A Look at my{" "}
              <a
                className="underline-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/vikashchander"
              >
                github
              </a>{" "}
              and connect with me on{" "}
              <a
                className="underline-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/vikashchander/"
              >
                linkedin{" "}
              </a>
              My Interest to build projects with new technologies.
              <br />
              excited to solve real world problems . I am always excited to do
              work with like business minded peoples working on
              <strong>
                <code className="px-2 mx-1 badge badge-info"> NodeJS </code>
              </strong>
              and
              <strong>
                <code className="px-2 mx-1 badge badge-secondary">ReactJS</code>
              </strong>
              , its frameworks always ready to use new frameworks and libraries.
              <br />
            </p>
            <p>
              <strong className="text-danger">
                I
                <span
                  role="img"
                  className="mx-1"
                  aria-label="Tradescantia pallida"
                >
                   💙💙
                </span>
                Open source ||
              </strong>
              <strong className="text-primary mx-1">
                alumni @
                <a href="https://www.udacity.com/" className="text-info mx-1">
                  Udacity
                </a>
                ||
              </strong>
              <code>
                <strong className="mx-2">Mern Stack Developer</strong>
              </code>
              <br />
              <strong>
                <code className="text-primary"> Javascript + Typescript </code>:
              </strong>
              <span className="mx-1">Best Combos ;</span>
              <strong>
                <code className="mx-2 badge badge-pill badge-primary">
                  Python
                </code>
              </strong>
              for competitive programing and data science<strong>; </strong>
            </p>
          </div>
    </React.Fragment>
    )
  }
}

export default Intro;
